import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import StarRatingComponent from 'react-star-rating-component';

import Layout from '../components/layout';


export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <h1>{post.frontmatter.title}</h1>
      <h2>{post.frontmatter.genre}</h2>
      <h3>{post.frontmatter.date}</h3>
      <StarRatingComponent
        editing={false}
        starCount={10}
        value={post.frontmatter.rating}
      />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        genre
        rating
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`